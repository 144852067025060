import React, { useEffect } from 'react'
import Carousel from '../components/Carousel';


const reviewsData = [
    {
        rating: 4,
        title: 'Excellent App!',
        description: `I am delighted to share my experience as a vendor who partnered with Homemet,  the leading building material and service app. This collaboration is a game-changer for my business.`,
        image: require('../assets/img/person_1.jpg'),
        name: 'Rohit Jaiswal',
        role: 'Supplier'
    },
    {
        rating: 4,
        title: 'Awesome functionality!',
        description: `Partnering with Homemet has been a great experience. It has boosted our visibility, improved our communication with customers, and streamlined our operations.`,
        image: require('../assets/img/person_2.jpg'),
        name: 'Sanjay Patel',
        role: 'Supplier'
    },
    {
        rating: 4,
        title: 'Best App To Expand Bussiness',
        description: `I wholeheartedly endorse Homemet to any vendor looking to expand their reach and grow their business in the building materials and services industry. It's a platform that delivers on its promises.`,
        image: require('../assets/img/person_3.jpg'),
        name: 'Bhaskar Chandrakar',
        role: 'Supplier'
    }
];

export default function Vendor() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main id="main">
            <section className="hero-section-custom hero-section inner-page">
                <div className="wave">
                    <svg width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    // xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                                <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path" />
                            </g>
                        </g>
                    </svg>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="headr-vendor-align">
                                <span className="headr-vendor-text-1">
                                    GIVE NEW HEIGHTS TO YOUR BUSINESS
                                </span>
                            </div>
                            <img style={{ marginTop: '-35px', width: '100%' }} src={require("../assets/img/VendorPage/Banner_Vendor.png")} alt="Image" className="img-fluid " />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 me-auto">
                            <h2 className="mb-4">Wide Customer Base</h2>
                            <p className="mb-4">Joining forces with Homemet  means gaining access to our extensive customer base, which includes builders, contractors, architects, and homeowners. We provide you with an opportunity to showcase your materials to a broad and diverse audience.</p>
                            {/* <p><a href="#">Read More</a></p> */}
                        </div>
                        <div className="col-md-6" data-aos="fade-left">
                            <img src={require("../assets/img/VendorPage/Vendor_CustomerBase.jpg")} alt="Image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 ms-auto order-2">
                            <h2 className="mb-4">Real-TimeDashboard</h2>
                            <p className="mb-4">Provides Monitoringtracking like pending and received payments, future jobs, and work status in a highly user-friendly and accurate manner.</p>
                            {/* <p><a href="#">Read More</a></p> */}
                        </div>
                        <div className="col-md-6" data-aos="fade-right">
                            <img src={require("../assets/img/VendorPage/Vendor_Dashboard.jpg")} alt="Image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 me-auto">
                            <h2 className="mb-4">Track your Payments</h2>
                            <p className="mb-4">Managing payments has never been this convenient! At Homemet, we understand the importance of staying on top of your payment schedules. That's why we've introduced our seamless In-App Payment Tracking feature.</p>
                            {/* <p><a href="#">Read More</a></p> */}
                        </div>
                        <div className="col-md-6" data-aos="fade-left">
                            <img src={require("../assets/img/VendorPage/Vendor_TrackPayment.jpg")} alt="Image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 ms-auto order-2">
                            <h2 className="mb-4">Competitive Advantage</h2>
                            <p className="mb-4">By listing your materials on our platform, you gain a competitive edge. Customers trust our network for their construction needs, and your products can be their go-to choice.</p>
                            {/* <p><a href="#">Read More</a></p> */}
                        </div>
                        <div className="col-md-6" data-aos="fade-right">
                            <img src={require("../assets/img/VendorPage/Vendor_Trust.jpg")} alt="Image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <Carousel title="Our Happy Users" reviews={reviewsData} />
            <section className="section cta-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 me-auto text-center text-md-start mb-5 mb-md-0">
                            <h2>Download Homemet now, experience the future of building and farming</h2>
                        </div>
                        <div className="col-md-4 text-center text-md-end">
                            <p>
                                <a style={{ width: '300px' }} target="_blank" href="https://www.indusappstore.com/details/indus/1055115" className=" d-inline-flex align-items-center">
                                    <img src={require("../assets/img/AppStore/PartnerApp-Indus.png")} alt="" className="img-fluid" />
                                    {/* <i  class="bx bxl-play-store"></i><span>Customer App</span> */}
                                </a>
                            </p>
                            <p>
                                <a style={{ width: '300px' }} target="_blank" href="https://play.google.com/store/apps/details?id=com.homemetpartner" className=" d-inline-flex align-items-center">
                                    <img src={require("../assets/img/AppStore/PartnerApp-PlayStore.png")} alt="" className="img-fluid" />
                                    {/* <i  class="bx bxl-play-store"></i><span>Partner App</span></a> */}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
