import React from 'react';
import Main from '../pages/Main';
import Customer from '../pages/Customer';
import Vendor from '../pages/Vendor';
import Services from '../pages/Services';
import ContactUs from '../pages/ContactUs';


const screenComponents = {
    main: Main,
    customer: Customer,
    vendor: Vendor,
    contact:ContactUs,
    services:Services
    // Add more screen mappings as needed
  };
  
const DynamicScreen = ({ screenName }) => {
  const ScreenComponent = screenComponents[screenName];

  if (!ScreenComponent) {
    return <div>Screen not found</div>; // Handle cases where the screen doesn't exist
  }

  return <ScreenComponent />;
};

export default DynamicScreen;
