import React from 'react';
import PropTypes from 'prop-types';

export default function Carousel({ title, reviews }) {

    console.log(reviews, "reviews");
  return (
    <section className="section border-top border-bottom">
      <div className="text-center">
        <h2 className="section-heading">{title}</h2>
      </div>
      <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {reviews.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide-to={index}
              className={index === 0 ? 'active' : ''}
              aria-label={`Slide ${index + 1}`}
              aria-current={index === 0 ? 'true' : undefined}
            ></button>
          ))}
        </div>
        <div className="container carousel-inner">
          {reviews.map((review, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
              <div className="review text-center">
                <p className="stars">
                  {[...Array(5)].map((_, starIndex) => (
                    <span key={starIndex} className={`bi bi-star-fill ${starIndex < review.rating ? '' : 'muted'}`}></span>
                  ))}
                </p>    
                <h3>{review.title}</h3>
                <blockquote>
                  <p>{review.description}</p>
                </blockquote>
                <p className="review-user">
                  <img src={review.image} alt="User" className="img-fluid rounded-circle mb-3" />
                  <span className="d-block">
                    <span className="text-black">{review.name}</span>, &mdash; {review.role}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </section>
  );
}

Carousel.propTypes = {
  title: PropTypes.string.isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      rating: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    })
  ).isRequired,
};
