import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../css/style.css'

export default function Header() {
    const [scrolled, setScrolled] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setScrolled(offset > 50);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const headerClasses = scrolled ? "header header-scrolled" : "header header-default";
    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };
    return (
        <header id="header"      className={headerClasses}>
            <div 
            // className={headerClasses}
             className={`container ${headerClasses}`}
            >
                <div className="logo">
                    <Link to="/">
                        <img src={require("../assets/img/HLoader.gif")} alt="Logo" className="img-fluid" />
                    </Link>
                </div>

                <nav id="navbar" className={`navbar ${isNavOpen ? 'open' : ''}`}>
                    <ul>
                        <li><Link className="btn" to="/">Home</Link></li>
                        <li><Link className="btn" to="/customer">Customer</Link></li>
                        <li><Link className="btn" to="/vendor">Vendor</Link></li>
                        <li><Link className="btn" to="/contact">Contact Us</Link></li>
                        <li><Link className="btn" to="/services/brick/raipur">Our Services</Link></li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle" onClick={toggleNav}></i>
                </nav>
            </div>
        </header>
    )
}
