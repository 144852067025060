import React, { useState, useEffect } from 'react';
import BackToTopButton from "../../components/BackToTopButton.jsx";

export default function RefundPrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container">
            <header id="header" style={{ backgroundColor: '#9fdfa0cc' }} className="fixed-top py-3">
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="logo">
                        <a href="#">
                            <img src={require("../../assets/img/HLoader.gif")} alt="" className="img-fluid" />
                        </a>
                    </div>
                </div>
            </header>

            <section className="mt-5 pt-5">
                <div className="container">
                    <h1 className="text-center mb-4" id="contents">Homemet Refund Policy</h1>
                    <article>
                        <section className="mb-5">
                            <h2 id="personal-info">Cancellation or Refund Policy</h2>
                            <p>
                                This page is used to inform visitors about our refund policies. We aim to provide the best service possible and value your satisfaction. This refund policy outlines the conditions under which we offer refunds for our services.
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="collection">Eligibility for Cancellation or Refunds</h2>
                            <p>
                                You may be eligible for a refund if:
                                <ul>
                                    <li>Case 1 <p>If the HOMEMET partner app does not deliver 30 job calls within a year, the registration fee will be refundable (Excluding GST & platform charges).</p> </li>
                                    <li>Case 2 <p>Technical issues prevented the use of the service (full refund).</p></li>
                                    <li>Case 3 <p>You were charged incorrectly or the wrong billing was done (full refund)</p></li>
                                    <li>Case 4 <p>If you purchase a subscription, you can cancel it at any time. </p></li>
                                </ul>
                                However, cancellations will take effect at the end of the current billing cycle. No refunds will be issued for the remaining period of the current billing cycle.
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="use">Time Frame for Refund Requests</h2>
                            <p>
                                <ul>
                                    <li>Case 1 <p>Refund requests must be submitted within 15 days after one year from the purchase date.</p> </li>
                                    <li>Case 2 & 3 <p>Refund requests must be submitted within 15 days of the purchase date.</p></li>

                                </ul>
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="disclosure">Process for Requesting a Cancellation or Refund</h2>
                            <p>
                                To request a refund, please follow these steps:
                                <ul>
                                    <li>Case 1 <p>Single click refund process an automated 'Refund' button would appear in the menu HOMEMET partner app.</p> </li>
                                    <li>Case 2<p> Contact our customer support at admin@axlesys.in, Provide your purchase details, including the transaction ID and reason for the refund.</p></li>

                                </ul>
                                Our team will review your request and respond within 5 business days.

                                Refund Approval and Issuance
                                Once your refund request is reviewed and approved, the refund will be processed, and a credit will be applied to your original method of payment within 10 business days.
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="storage">Non-Refundable Situations</h2>
                            <p>
                                Refunds will not be issued for:
                                Subscription for Services (service Plus1, service Plus2, service Plus3) and Location (Location +3, Location +5, Location +10)
                                Requests are made after the 15-day refund period.
                                Promotional or discounted services unless otherwise stated.
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="misc">Policy Updates</h2>
                            <p>
                                We reserve the right to update this refund We reserve the right to update this cancellation or refund policy at any time. Any changes will be posted on this page. We encourage you to review this policy periodically for any updates. This policy is effective as of Jun 01, 2024
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="misc">Contact Us</h2>
                            <p>
                              If you have any questions about our refund policy, please contact us at admin@axlesys.in.
                            </p>
                        </section>

                        <section className="mb-5 text-center">
                            <h2 id="misc">This cancellation or refund policy page was created and modified by
                                <br />
                                Axlesys Pvt. Ltd.</h2>
                        </section>
                    </article>
                </div>
            </section>
            <BackToTopButton />
        </div>
    );
}
