import React, { useEffect } from 'react'
import Carousel from '../components/Carousel';

const testimonials = [
    {
        rating: 4,
        title: "Excellent App!",
        description: `I wholeheartedly recommend Homemet to anyone in the construction or renovation space. It's a must-have tool for simplifying projects and achieving exceptional results.`,
        image: require("../assets/img/person_1.jpg"),
        name: "Mohan Das Vaishnav",
        role: "Customer",
    },
    {
        rating: 4,
        title: "Best Pricing!",
        description: `The transparency in pricing was another standout feature. I could review quotes and estimates with ease, and there were no hidden costs to worry about. This level of transparency instilled trust and made the entire process stress-free.`,
        image: require("../assets/img/person_2.jpg"),
        name: "Rajeev Agarwal",
        role: "Customer",
    },
    {
        rating: 4,
        title: "Awesome Quality & Services!",
        description: `What truly stood out for me was the quality of the building materials I received. They were not only top-notch but also best in price. I am thankful to Homemet team for proving excellent app.`,
        image: require("../assets/img/person_3.jpg"),
        name: "Tejinder Singh",
        role: "Customer",
    },
];

export default function Customer() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div><main id="main">

            <section className="hero-section-custom hero-section inner-page">
                <div className="wave">
                    <svg
                        width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                                <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path"></path>
                            </g>
                        </g>
                    </svg>

                </div>

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="headr-align-customer">
                                <span className="headr-text-1">We'll help you Build your dream home!</span>
                                <div>
                                    <span className="headr-text-2">
                                        Build Your
                                    </span>
                                    <span className="headr-text-3">
                                        Dream Home
                                    </span>
                                </div>
                            </div>
                            <img style={{ marginTop: "-35px", width: "100%" }}
                                src={require("../assets/img/CustomerPage/Banner_Customer.png")} alt="Image" className="img-fluid headr-img" />

                        </div>
                    </div>
                </div>

            </section>

            <section className="section pb-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 me-auto">
                            <h2 className="mb-4">Our Services</h2>
                            <p className="mb-4">Experience the Power of Versatility with Homemet - Your One-Stop Solution for Multiple Building Material Services and Agricultural needs.</p>
                        </div>
                        <div className="col-md-6" data-aos="fade-left">
                            <img src={require("../assets/img/CustomerPage/Customer_Service.jpg")} alt="Image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 ms-auto order-2">
                            <h2 className="mb-4">Best Pricing</h2>
                            <p className="mb-4">You're choosing affordability without compromise. We're delivering the best Materials and services prices from our trusted vendors by competitive quotation and service estimation that fits your budget.</p>
                            {/* <!-- <p><a href="#">Read More</a></p> --> */}
                        </div>
                        <div className="col-md-6" data-aos="fade-right">
                            <img src={require("../assets/img/CustomerPage/Customer_BestPrice.jpg")} alt="Image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section pb-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 me-auto">
                            <h2 className="mb-4">Work Progress and Payment Tracking</h2>
                            <p className="mb-4">Say goodbye to construction chaos and embrace the simplicity and clarity of our Work Progress and Payment Tracking System. It's time to build smarter and easier not tedious.</p>
                            {/* <!-- <p><a href="#">Read More</a></p> --> */}
                        </div>
                        <div className="col-md-6" data-aos="fade-left">
                            <img src={require("../assets/img/CustomerPage/Customer_Payment.jpg")} alt="Image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 ms-auto order-2">
                            <h2 className="mb-4">Feedback and Rating</h2>
                            <p className="mb-4">At Homemet we value your experience with our vendors and their services. Your feedback helps us maintain high standards and ensures your satisfaction. Here's how you can rate, provide feedback, and express your appreciation for vendor services.</p>
                            {/* <!-- <p><a href="#">Read More</a></p> --> */}
                        </div>
                        <div className="col-md-6" data-aos="fade-right">
                            <img src={require("../assets/img/CustomerPage/Customer_Feedback.jpg")} alt="Image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <Carousel title='Our Happy Users' reviews={testimonials} />
            <section className="section cta-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div
                            className="col-md-8 me-auto text-center text-md-start mb-5 mb-md-0"
                        >
                            <h2>Download Homemet now, experience the future of building and farming</h2>
                        </div>
                        <div className="col-md-4 text-center text-md-end">
                            <p>
                                <a style={{ width: "300px" }} target="_blank" href="https://play.google.com/store/apps/details?id=com.homemetcustomer"
                                    className=" d-inline-flex align-items-center">
                                    <img src={require("../assets/img/AppStore/CustometrApp-PlayStore.png")} alt="" className="img-fluid" />
                                </a>
                            </p>
                            {/* <!-- <p>
                                <a style="width: 200px;" target="_blank" href="https://play.google.com/store/apps/details?id=com.homemetpartner" className="btn d-inline-flex align-items-center"
                                ><i className="bx bxl-play-store"></i><span>Partner App</span></a
                                >
                            </p> --> */}
                        </div>
                    </div>
                </div>
            </section>
        </main>
        </div>
    )
}
