// BackToTopButton.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const BackToTopButton = ({ threshold = 300, bottom = '20px', right = '20px' }) => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > threshold) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [threshold]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        showButton && (
            <button 
                onClick={scrollToTop} 
                className="btn btn-primary position-fixed"
                style={{ bottom, right, zIndex: '1000' }}
            >
                ↑ Back to top
            </button>
        )
    );
}

BackToTopButton.propTypes = {
    threshold: PropTypes.number,
    bottom: PropTypes.string,
    right: PropTypes.string,
};

export default BackToTopButton;
