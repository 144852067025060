import React, { useEffect } from 'react'
import BackToTopButton from '../../components/BackToTopButton'

export default function VendorTermsAndConditions() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div >
            <header id="header" style={{ backgroundColor: '#9fdfa0cc' }} className="fixed-top py-3">
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="logo">
                        <a href="#">
                            <img src={require("../../assets/img/HLoader.gif")} alt="" className="img-fluid" />
                        </a>
                    </div>
                </div>
            </header>

            <section className="mt-5 pt-5">
                <div className="container">

                    <article>

                        <header className='text-center'>
                            <h1 id="contents">Terms And Conditions</h1>
                            <h3 id="personal-info" className="heading-s">Last updated: Jun 01, 2024</h3>
                            <h6 id="misc_children">Please read these terms and conditions carefully before using Our Service.</h6>
                        </header>

                        <br />
                        <h2 id="personal-info" className='text-center'>Interpretation and
                            Definitions</h2>
                        <h2 id="misc_children" className="heading-s">Interpretation</h2>
                        <p className='text-color-dark' id="misc_children">The words of which the initial letter is capitalized have meanings defined under the
                            following conditions. The following definitions shall have the same meaning regardless of whether they appear in
                            singular or in plural form.
                        </p>
                        <br />
                        <h2 id="misc_children" className="heading-s">Definitions</h2>
                        <p className='text-color-dark' id="misc_children">For the purposes of these Terms and Conditions:
                            <br /><br />
                            <div >
                                <b>Application</b> means the SaaS-based software program provided by the Company downloaded by You on any
                                electronic device, named HOMEMET.
                                <br />
                                <b>Application Store</b> means the digital distribution service operated and developed by Apple Inc. (Apple App
                                Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.
                                <br />
                                <b>Affiliate</b> means an entity that controls, is controlled by or is under common control with a party, where
                                "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for
                                the election of directors or other managing authority.
                                <br />
                                <b>Country</b> refers to: India
                                <br />
                                <b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to AXLESYS
                                PRIVATE LIMITED, Vrindavan Plaza, Raigarh.
                                <br />
                                <b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                                <br />
                                <b>Service</b> refers to the Application.
                                <br />
                                <b>Terms and Conditions</b> (also referred as "Terms") mean these Terms and Conditions that form the entire
                                agreement between You and the Company regarding the use of the Service.
                                <br />
                                <b>Third-party Social Media Service</b> means any services or content (including data, information, products or
                                services) provided by a third party that may be displayed, included or made available by the Service.
                                <br />
                                <b>You</b> mean the individual accessing or using the Service, or the company, or other legal entity on behalf
                                of
                                which such individual is accessing or using the Service, as applicable.
                                <br /><br />
                            </div>
                            We may terminate or suspend Your access immediately, without prior notice or liability, for any reason
                            whatsoever, including without limitation if You br/each these Terms and Conditions.
                            Upon termination, Your right to use the Service will cease immediately.

                        </p>


                        <h2 id="collection" className="heading-s">Acknowledgment</h2>

                        <p className='text-color-dark' >These are the Terms and Conditions governing the use of this Service and the
                            agreement that operates between You and the Company. These Terms and Conditions set out the rights and
                            obligations of all users regarding the use of the Service.
                            <br /><br />
                            Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and
                            Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
                            <br /><br />
                            By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any
                            part of these Terms and Conditions then You may not access the Service.
                            <br /><br />
                            You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
                            <br /><br />
                            Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy
                            Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and
                            disclosure of Your personal information when You use the Application or the Website and tells You about Your
                            privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.

                        </p>



                        <h2 id="use" className="heading-s">Service</h2>
                        <p className='text-color-dark' >
                            (a) To provide a platform by HOMEMET to facilitate bidding between the Client and independent third parties
                            (referred to as 'Service Professionals' and 'Suppliers') for the supply of various home construction, earthwork,
                            and agricultural services.
                            <br />
                            (b) “Services” includes (“Service Professional and Supplier”) Services. HOMEMET does not provide the Services
                            and is not responsible for their provision. Service professionals and suppliers are solely responsible and
                            liable for the Services that they provide or otherwise provide through HOMEMET. HOMEMET and its affiliates do
                            not employ service professionals and suppliers, nor our agents, contractors, or partners of its affiliates.
                            SERVICE PROFESSIONALS AND SUPPLIERS DO NOT HAVE THE ABILITY TO BIND HOMEMET OR REPRESENT THEM
                            <br />
                            (c)Please note that HOMEMET is for use within India only. You agree to avail these Services from any other legal
                            jurisdiction territory of India, you shall be deemed to have accepted the HOMEMET terms and conditions
                            applicable to the Indian jurisdiction only.
                            <br />
                            (d) A key part of the Services is HOMEMET's ability to send you to push notifications, electronic mail, or
                            WhatsApp messages regarding your booking, your use of the Services, or as part of its promotional and marketing
                            strategies. Although you may opt out of receiving these text messages by contacting us at admin@axlesys.in or
                            through in-platform settings, you agree and acknowledge that the Services (or a portion of the Services) will be
                            available to you HOMEMET's ability to provide may be affected.
                            <br />
                            (e) In some cases, you may be required to submit proof of identity in order to avail of the Services, and you
                            hereby agree to do so. Failure to comply with this request may result in your inability to use the Services.

                        </p>



                        <h2 id="disclosure" className="heading-s">Account Creation</h2>

                        <p className='text-color-dark' id="disclosure_partners">(a) In order to avail of the Services, you will be required to create an account on
                            the Platform (“Account”). For this account, you may need to submit certain details, including your phone number,
                            HOMEMET sends you an OTP message to verify the phone number you provided, to create an account, you must be at
                            least 18 years. You agree to all information furnished in connection with your Account and shall remain accurate
                            and true. You agree to promptly update your details on the Platform in the event of any change or modification
                            of this information.
                            <br />
                            (b) You are solely responsible for maintaining the security and confidentiality of your Account and agree to
                            immediately notify us of any disclosure or unauthorized use of your Account or any other br/each of security with
                            respect to your Account.
                            <br />
                            (c) You are liable and accountable for all activities that take place through your Account, including activities
                            performed by persons other than you. We shall not be liable for any unauthorized access to your Account.
                            <br />
                            (d) You agree to receive communications from us regarding (i) requests for payments, (ii) information about us
                            and the Services, (iii) promotional offers and services from us and our third-party partners, and (iv) any other
                            matter in relation to the Services.

                        </p>



                        <h2 id="storage" className="heading-s">User Content</h2>
                        <p className='text-color-dark' >
                            (a) Our Platform may contain interactive features or services that allow users who have created an account with
                            us to post, upload, publish, display, transmit, or submit comments, reviews, suggestions, feedback, ideas, or
                            other content on or through the Platform (“User Content”).
                            <br />
                            (b) As part of the effective provision of the Services and quality control purposes, we may request reviews from
                            you about Service Professionals and you agree and acknowledge that Service Professionals may provide reviews
                            about you to us. You must not knowingly provide false, inaccurate, or misleading information in respect of the
                            reviews. Reviews will be used by us for quality control purposes and to determine whether Customers and Service
                            Professionals are appropriate users of the Platform. If we determine at our sole discretion that you are not an
                            appropriate user, we reserve the right to cancel your registration and remove you from our Platform.
                            <br />
                            (c) You grant us a non-exclusive, worldwide, perpetual, irrevocable, transferable, sublicensable, and
                            royalty-free license to (i) use, publish, display, store, host, transfer, process, communicate, distribute, make
                            available, modify, adapt, translate, and create derivative works of, the User Content, for the functioning of,
                            and in connection with, the Services and (ii) use User Content for the limited purposes of advertising and
                            promoting the Services, or furnishing evidence before a court or authority of competent jurisdiction under
                            applicable laws.
                            <br />
                            (d) In connection with these Terms and the licenses granted under this clause, you hereby waive any claims
                            arising out of any moral rights or other similar rights relating to the User Content.
                            <br />
                            (e) You agree and acknowledge that HOMEMET may, without notice to you, remove, or otherwise restrict access to
                            User Content that, in its sole discretion, violates of these Terms.



                        </p>



                        <h2 id="misc" className="heading-s">Consent of Use Data</h2>

                        <p className='text-color-dark' id="misc_choice">(a) You agree that we may, in accordance with our Privacy Policy, collect and use your
                            personal data. The Privacy Policy is available at https://www.homemet.in/privacy-policy and it explains the
                            categories of personal data that we collect or otherwise process about you and the manner in which we process
                            such data.
                            <br />
                            (b) In addition to any consent, you may provide pursuant to the Privacy Policy, you hereby consent to us sharing
                            your information with our or other third-party service providers. We may use information and data pertaining to
                            your use of the Services for the provision of the Services, analytics, trend identification, and purposes of
                            statistics to further enhance the effectiveness and efficiency of our Services, and provision of beneficial
                            schemes, new offers, and for experience enhancement.
                            <br />
                            (c) Subject to applicable laws, we may be directed by law enforcement agencies or the government and related
                            bodies to disclose data in relation to you in connection with criminal or civil proceedings. You understand and
                            agree that in such instances we shall have the right to share such data with relevant agencies or bodies.


                        </p>


                        <h2 id="misc" className="heading-s">Quotation</h2>

                        <p className='text-color-dark' id="misc_choice">(a) Ordering: The Platform allows the Customer to request various services based on the
                            available services. In order to make a booking, you must follow the instructions on the Platform and provide the
                            required information. HOMEMET reaches out to multiple qualified suppliers and professionals near to you, for the
                            service or product you request. If, in the unlikely event that we are unable to locate the supplier and
                            professional for a specific time, we will contact you to find an alternative solution at an appropriate time.
                            <br />
                            (b) Quotation: HOMEMET sends booking information to multiple qualified suppliers and professionals through push
                            notifications, so that the customer receives various quotations.
                            <br />
                            (c) Confirmation: Once the customer selects one of those quotations, a push notification is delivered by HOMEMET
                            to the selected supplier or professional. If, this order is based on services (Construction, Tiles work,
                            Painting, Plumber, electrician, etc.) Customers can request for more than one of those quotations for
                            estimation. Actual measurements and accurate estimates are made available to the customer by the supplier or
                            professional after the customer receives the request for estimation notification. A notification is sent by
                            HOMEMET to the selected supplier or professional by selecting the estimate from the customer-received
                            estimation. This booking gets added to the scheduled list of the customer and supplier or professional.
                            <br />
                            (d) Delivery: The delivery of service or material by the supplier or professional will be done only when the
                            start-job code given by the customer matches and only then the work will start. Similarly, the completion of
                            work will be marked with the end-job code given by the customer.
                            <br />
                            (e) Payment: Once the Services or Materials have been delivered or in the course of delivery for long works, you
                            will be required to make payment directly to the supplier or professional in accordance with these Terms or as
                            specified on the application. There are two types of payment, Type 1 In which the customer has to pay the full
                            amount, and Type 2 In which the customer has to pay by work status, details of work status in percentage by the
                            supplier or professional in the application option "Generate-Bill" Will be done. Payment and schedule are for
                            illustration purposes only.
                            <br />
                            (f) Feedback: Within working days after the completion of work or full payment, the customer is required to
                            provide a rating to the supplier or professional for evaluation of the work. If this is not the case, a certain
                            rating will be provided by the application. So we recommend prompting the customer to evaluate or rate the
                            supplier or professional on time to secure their rating.
                            <br />
                            (g) Cancellation: Cancellation in HOMEMET Application After 2 consecutive cancellations, the cancellation option
                            will be disabled for a few hours, in any case, the user will be suspended or blacklisted if the same process is
                            repeated continuously. (i) Actions by the customer Cancellation process can be done before starting the job (ii)
                            The Cancellation process can be done by the supplier or professional even before the quotation or estimation is
                            selected or the job is confirmed.



                        </p>



                        <h2 id="misc" className="heading-s">Pricing, Fee, and Payment Terms</h2>
                        <p className='text-color-dark' id="misc_choice">
                            (a) HOMEMET offers one free service or a bundled selection of services to "suppliers or service professionals".
                            Beyond this initial free service, any additional services will be available for purchase within the app.
                            <br />
                            (b) HOMEMET reserves the right to charge you for the different Services you may avail and/or for any other
                            facilities you may opt for, from time to time, on or via the Platform.
                            <br />
                            (c) Payment shall be made by the Customer directly to the "suppliers or service professionals" in respect of the
                            Materials or Services, HOMEMET shall not be liable for the Payment, directly or indirectly, in any manner
                            whatsoever.

                        </p>


                        <h2 id="misc" className="heading-s">Customer Conduct</h2>

                        <p className='text-color-dark' id="misc_choice">(a) HOMEMET prohibits discrimination against Service Professionals, including on the basis of
                            race, religion, caste, national origin, disability, sexual orientation, sex, marital status, gender identity,
                            age, or any other characteristic that may be protected under applicable law. Such discrimination includes but is
                            not limited to any refusal to accept Services based on any of these characteristics.
                            <br />
                            (b) We request that you treat all Service Professionals with courtesy and respect and that you provide them with
                            a safe, clean, and appropriate location to perform the Services. Service Professionals shall be entitled to
                            refuse to perform Services if you have not provided a safe, clean, and appropriate location for them, or if your
                            behavior towards them is in a manner that is discourteous, disrespectful, abusive, or otherwise inappropriate.
                            We reserve the right to withhold access to the Services and otherwise limit your access to Services at our
                            absolute discretion if you behave towards any Service Professional in a manner that is discourteous,
                            disrespectful, or abusive, or which we otherwise deem to be inappropriate or unlawful.

                            <br />
                            (c) You agree that you will be liable for discriminating against Service Professionals or for any failure,
                            intentional or otherwise, to provide the Service Professionals a safe, clean, and appropriate location for them
                            to perform the Services. Additionally, you will also disclose any and all information that may have a bearing on
                            the ability of the Service Professional to perform the Services or impact the Services Professional’s health,
                            safety, or well-being, to HOMEMET and the Service Professional.
                            <br />
                            (d) You agree that in the event a Service Professional behaves in a manner that is discourteous, disrespectful,
                            abusive, inappropriate, or in violation of the law, you shall be required to report such incident to
                            admin@axlesys.in at the earliest but in any event within 48 (forty-eight) hours of such incident.




                        </p>



                        <h2 id="misc" className="heading-s">Third-Party Services</h2>

                        <p className='text-color-dark' id="misc_choice">(a) HOMEMET Platform may include services, content, documents, and information owned by,
                            licensed to, or otherwise made available by, a third party (“Third Party Services and Product”) and contain
                            links to Third Party Services and products. You understand and acknowledge that Third Party Services and
                            Services are the sole responsibility of the third party that created or provided them and that use of such
                            Third-Party Services is solely at your own risk.
                            <br />
                            (b) We make no representations and exclude all warranties and liabilities arising out of or pertaining to such
                            Third-Party Services or products, including their accuracy or completeness. Should you avail of a Third-Party
                            Service or product, you shall be governed and bound by the terms and conditions and privacy policy of the third
                            parties providing the Third-Party Services or products. Further, all intellectual property rights in and to
                            Third Party Services or products are the property of the respective third parties.

                        </p>


                        <h2 id="misc" className="heading-s">Your Responsibilities</h2>

                        <p className='text-color-dark' id="misc_choice">(a) You represent and warrant that all information that you provide in relation to the
                            Services and Product is complete, true, and correct on the date of agreeing to these Terms and shall continue to
                            be complete, true, and correct while you avail the Services and/or deliver the services. Should any information
                            that you provide change during the existence of these Terms, you undertake to immediately br/ing such change to
                            our notice. We do not accept any responsibility or liability for any loss or damage that you may suffer or incur
                            if any information, documentation, material, or data, provided to avail the Services is incorrect, incomplete,
                            inaccurate, or misleading or if you fail to disclose any material fact.
                            <br />
                            (b) You shall extend all cooperation to us in our defense of any proceedings that may be initiated against us
                            due to a br/each of your obligations or covenants under these Terms.
                            <br />
                            (c) In respect of the User Content, you represent and warrant that:
                            <br />
                            <div >
                                i. You own all intellectual property rights (or have obtained all necessary permissions) to provide User Content
                                and to grant the licenses under these Terms;
                                <br />
                                ii. you are solely responsible for all activities that occur on or through your account on the Platform and all
                                User Content;
                                <br />
                                iii. the User Content does not and shall not violate any of your obligations or responsibilities under other
                                agreements;
                                <br />
                                iv. the User Content does not and shall not violate, infringe, or misappropriate any intellectual property right
                                or other proprietary right including the right of publicity or privacy of any person or entity;
                                <br />
                                v. the User Content does not and shall not contain any viruses, corrupted data, or other harmful, disruptive, or
                                destructive files or content;
                                <br />
                                vi. the User Content does not and shall not violate any third-party rights;
                                <br />
                                vii. the User Content
                                <br />

                                (A) does not belong to any other person to which you do not have any right,
                                <br /> (B) does not threaten the unity, integrity, defense, security or sovereignty of India, friendly relations
                                with foreign states, public order, cause incitement to the commission of any cognizable offense, prevents
                                investigation of any offense, or is insulting another nation,
                                <br />(C) is not defamatory, grossly harmful, blasphemous, paedophilic, invasive of another’s privacy,
                                discriminatory based on gender, ethnically objectionable, disparaging, relating to, or encouraging money
                                laundering or gambling, libelous, hateful, racist, violent, obscene, pornographic, unlawful, harmful to
                                children, or
                                <br /> (D) otherwise offensive, objectionable, or restricts, or inhibits, any other person from using or enjoying
                                the Services.
                            </div>
                            (d) You shall not use the Services in any manner except as expressly permitted in these Terms. Without limiting
                            the generality of the preceding sentence, you shall not:
                            <br />
                            <div>
                                i. You shall not use the Services in any manner except as expressly permitted in these Terms. Without limiting
                                the generality of the preceding sentence, you shall not:
                                <br />
                                ii. infringe any proprietary rights, including but not limited to copyrights, patents, trademarks, or trade
                                secrets of any party;
                                <br />
                                iii. except as may be provided hereunder, copy, display, distribute, modify, publish, reproduce, store,
                                transmit, post, translate, create any derivative works from or license the Services;
                                <br />
                                iv. use the Services to transmit any data, or send or upload any material that contains viruses, Trojan horses,
                                worms, timebombs, keystroke loggers, spyware, adware, or any other harmful programs, or similar computer code,
                                designed to adversely affect the operation of any computer software or hardware;
                                <br />
                                v. use any robot, spider, other automated devices, or manual process to monitor or copy the Services or any
                                portion thereof;
                                <br />
                                vi. use the Services in (A) any unlawful manner, (B) for fraudulent or malicious activities or (C) in any manner
                                inconsistent with these Terms;
                                <br />
                                vii. decompile, reverse engineer, or disassemble the Services;
                                <br />
                                viii. link to, mirror, or frame, any portion of all or any of the Services; or
                                <br />
                                ix. violate applicable laws in any manner.
                                <br />
                            </div>
                            (e) You warrant that you shall not engage in any activity that interferes with or disrupts the Services.
                            <br />
                            (f) You shall not attempt to gain unauthorized access to any portion or feature of the Services, any other
                            systems or networks connected to the Services, to any of our servers, or through the Platform by hacking,
                            password mining, or any other illegitimate means.
                            <br />
                            (g) You shall not directly or indirectly, in any capacity, solicit, attempt to influence, engage, approach, or
                            accept or encourage the solicitations or approach of, a Service Professional from whom you have availed Pro
                            Services, to either terminate or otherwise cease their registration on or engagement with the Platform, or avail
                            services the same as or similar to the Services independently, without bidding the Services through your
                            Account. You agree that this limitation is reasonable and fair and is necessary for the protection of the
                            privacy and security of Service Professionals and that this will not preclude you from obtaining services the
                            same as or similar to the Services through the Platform or other means. You further agree that any potential
                            harm to Service Professionals from the non-enforcement of this clause far outweighs any potential harm to you.


                        </p>


                        <h2 id="misc" className="heading-s">Our Intellectual Property </h2>

                        <p className='text-color-dark' id="misc_choice">(a) All rights, titles, and interests in, and to the Services, including all intellectual
                            property rights arising out of the Services, are owned by or otherwise licensed to us. Subject to compliance
                            with these Terms, we grant you a non-exclusive, non-transferable, non-sublicensable, revocable, and limited
                            license to use the Services in accordance with these Terms and our written instructions issued from time to
                            time. Any rights not expressly granted herein are reserved by HOMEMET or HOMEMETS’s licensors.
                            <br />
                            (b) We may request you to submit suggestions and other feedback, including bug reports, relating to the Services
                            from time to time (“Feedback”). We may freely use, copy, disclose, publish, display, distribute, and exploit the
                            Feedback we receive from you without any payment of royalty, acknowledgment, prior consent, or any other form of
                            restriction arising out of your intellectual property rights.
                            <br />
                            (c) Except as expressly stated in these Terms, nothing in these Terms should be construed as conferring any
                            right in, or license to, our or any third party’s intellectual property rights.


                        </p>



                        <h2 id="misc" className="heading-s">Terms and Termination</h2>

                        <p className='text-color-dark' id="misc_choice">
                            (a) These Terms shall remain in effect unless terminated in accordance with the terms hereunder.
                            <br />
                            (b) We may restrict, deactivate, or terminate your access to, or use of, the Services, or any portion thereof,
                            (i) immediately and at any point at our sole discretion, (A) if you violate or br/each any of the obligations,
                            responsibilities, or covenants under these Terms, (B) when you cease to become a user of our Services,

                            (C) you do not, or are likely not to qualify under applicable law, or the standards and policies of HOMEMET or
                            its affiliates, to access and use the Services, or

                            (D) violate or br/each the Community Guidelines, (ii) upon 30 (Thirty) days’ prior written notice to you, or
                            (iii) immediately for any legitimate business, legal, or regulatory reason.
                            <br />
                            (c) Upon termination of these Terms:
                            <div >
                                i. the Account will expire; <br />
                                ii. the Services will “time out”; and <br />
                                iii. these Terms shall terminate, except for those clauses that are expressly, or by implication, intended to
                                survive termination or expiry.
                            </div>
                        </p>


                        <h2 id="misc" className="heading-s">Disclaimers and Warranties</h2>

                        <p className='text-color-dark' id="misc_choice">(a) The Services are provided on an “as is” basis without warranty of any kind, express,
                            implied, statutory or otherwise, including without limitation the implied warranties of title, non-infringement,
                            merchantability, or fitness for a particular purpose. Without limiting the foregoing, we make no warranty that
                            the Services will meet your requirements or expectations.
                            <br />
                            (b) No advice or information, whether oral or written, obtained by you from us shall create any warranty that is
                            not expressly stated in the Terms.
                            <br />
                            (c) You agree and acknowledge that we are merely a Platform that connects you with Service Professionals, and we
                            shall not be liable in any manner for any obligations that have not been explicitly stated in these Terms. We
                            are not liable or responsible for the fulfillment of any bookings, for the performance of the Services by any
                            Service Professional, or for any acts or omissions of the Service Professionals during their provision of the
                            Services including any damage they may cause to property. By booking Services through the Platform, you are
                            entering into a contract with the relevant Service Provider for the provision of those services, and we accept
                            no responsibility or liability, nor do we make any warranty, representation, or guarantee in respect of the
                            Service Professional’s performance under that contract.
                            <br />
                            (d) You agree and acknowledge that soliciting or receiving services from any Service Professional independently
                            is solely at your own risk, and in such an event, you waive any rights that you may have under these Terms.
                            <br />
                            (e) We do not guarantee or warrant and we make no representation whatsoever regarding the reliability, quality,
                            or suitability of the Service and products.
                            <br />
                            (f) You hereby accept full responsibility for any consequences that may arise from your use of the Services or
                            products and expressly agree and acknowledge that we shall have absolutely no liability in this regard.
                            <br />
                            (g) HOMEMET will maintain a complaints management framework and will manage this framework on behalf of Service
                            Professionals in a reasonable manner and in accordance with the non-excludable requirements of relevant
                            applicable laws.
                            <br />
                            (h) To the fullest extent permissible by law, we, our affiliates, and our related parties, each disclaim all
                            liability for any loss or damage arising out of, or due to:
                            <div >
                                a. your use of, inability to use, or availability or unavailability of the Services or Materials;<br />
                                b. the occurrence or existence of any defect, interruption, or delays, in the operation or transmission of
                                information to, from, or through the Services, communications failure, theft, destruction, or unauthorized
                                access to our records, programs, services, servers, or other infrastructure relating to the Services;<br />
                                c. the failure of the Services to remain operational for any period of time; and <br />
                                d. the loss of any User Content and any other data in connection with your use of the Services.
                            </div>
                            <br />
                            (i) In no event shall HOMEMET, its officers, directors, and employees, or its contractors, agents, licensors,
                            partners, or be liable to you for any direct, special, indirect, incidental, consequential, punitive, reliance,
                            or exemplary damages (including without limitation, lost business opportunities, lost revenues, or loss of
                            anticipated profits or any other pecuniary or non-pecuniary loss or damage of any nature whatsoever, including
                            but not limited to any abuse or br/each of data), even if UC or an authorized representative had been advised of
                            the possibility of such damages, arising out of, or relating to (A) these Terms, (B) the Services or the Pro
                            Services, (C) your use or inability to use the Services or the Pro Services, or (D) any other interactions with
                            another user of the Services.
                            <br />
                            (j) To the maximum extent permitted by law, our liability shall be limited to the amount of commission we
                            receive in respect of a particular booking made on the Platform. In no event shall our total liability to you in
                            connection with these Terms exceed INR 10,000 (Rupees Ten Thousand).
                            <br />
                            (k) Nothing in these Terms will exclude or limit any warranty implied by law that it would be unlawful to
                            exclude or limit.
                            (l) Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or
                            consequential damages, which means that some of the above limitations may not apply. In these states, each
                            party's liability will be limited to the greatest extent permitted by law.


                        </p>



                        <h2 id="misc" className="heading-s">Indemnity</h2>

                        <p className='text-color-dark' id="misc_choice">You shall indemnify, defend at our option, and hold us, our parent companies, subsidiaries,
                            affiliates, and our officers, employees, directors, agents, and representatives, harmless from and against any
                            claim, demand, lawsuits, judicial proceeding, losses, liabilities, damages, and costs (including, without
                            limitation, all damages, liabilities, settlements, and attorneys’ fees), due to or arising out of your access to
                            the Services, use of the Services or material Services, violation of these Terms, or any violation of these
                            Terms by any third party who may use your Account.

                        </p>



                        <h2 id="misc" className="heading-s">Jurisdiction, Governing Lows, and Dispute Resolution</h2>

                        <p className='text-color-dark' id="misc_choice">HOMEMET These Terms shall be governed by and construed and enforced in accordance with the
                            laws of India. Subject to other provisions in this clause, the district court in Raigarh, Chhattisgarh shall
                            have exclusive jurisdiction over all issues arising out of these Terms or the use of the Services.


                        </p>

                        <h2 id="misc" className="heading-s">Miscellaneous Provisions</h2>

                        <p className='text-color-dark' id="misc_choice">(a) Changes to Terms: The Terms are subject to revisions at any time, as determined by us,
                            and all changes are effective immediately upon being posted on the Platform. It is your responsibility to review
                            these Terms periodically for any updates or changes. You will be deemed to have accepted the changes made to
                            these Terms if you continue to use the Platform once it has been posted.
                            <br />
                            (b) Modification to the Services: We reserve the right at any time to add, modify, or discontinue, temporarily
                            or permanently, the Services (or any part thereof), with or without cause. We shall not be liable for any such
                            addition, modification, suspension, or discontinuation of the Services.
                            <br />
                            (c) Severability: If any provision of these Terms is determined by any court or other competent authority to be
                            unlawful or unenforceable, the other provisions of these Terms will continue to be in effect. If any unlawful or
                            unenforceable provision would be lawful or enforceable if a part of it were deleted, that part will be deemed to
                            be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear
                            intention of the clause, in which case the entirety of the relevant provision will be deemed to be deleted).
                            <br />
                            (d) Notice: All notices, requests, demands, and determinations for us under these Terms (other than routine
                            operational communications) shall be sent to admin@axlesys.in
                            <br />
                            (e) Third Party Rights: No third party shall have any rights to enforce any terms contained herein.
                            <br />
                            (f) Force Majeure: We shall have no liability to you if we are prevented from or delayed in performing our
                            obligations, or from carrying on our business, by acts, events, omissions, or accidents beyond our reasonable
                            control, including without limitation, strikes, failure of a utility service or telecommunications network, act
                            of God, war, riot, civil commotion, malicious damage, or compliance with any law or governmental order, rule,
                            regulation, or direction.

                        </p>


                        <h2 id="misc" className="heading-s">Contact Us</h2>

                        <p className='text-color-dark' id="misc_choice">If you have any questions about these Terms and Conditions, You can contact us::
                            <br />
                            By email: admin@axlesys.in
                            <br />
                            By visiting this page on our website: https://homemet.in/contact
                            <br />
                            By mail: 117,118 Vrindavan plaza, Raigarh, Chhattisgarh 496001


                        </p>


                        <h6 id="misc">This Terms and Conditions page was created and modified/generated by Homemet</h6>


                    </article>
                </div>
            </section>
            <BackToTopButton />
        </div >
    )
}
