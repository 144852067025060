import React, { useEffect } from 'react'
import "../css/style.css";
import Carousel from '../components/Carousel';

const reviewsData = [
    {
      rating: 4,
      title: 'Excellent App!',
      description: `I must say, this app is truly excellent. I've been using it for several months, and it has
                    consistently exceeded my expectations. What sets it apart is its remarkable user interface,
                    which is not only aesthetically pleasing but also incredibly intuitive. I've found its feature
                    set to be comprehensive and well-thought-out, making it a versatile tool for Keeping track of
                    individual tasks, deadlines, progress, and payment tracking. The app's performance has been
                    flawless, with no crashes or lags. Moreover, the customer support is top-notch; any questions or
                    issues are promptly addressed. I highly recommend this app to anyone looking for sales growth
                    and high revenue. It's been a game-changer for me.`,
      image: require('../assets/img/person_1.jpg'),
      name: 'Ayush Goyal',
      role: 'Supplier'
    },
    {
      rating: 4,
      title: 'This App is easy to use!',
      description: `I find this app incredibly user-friendly and intuitive. The interface is well-designed, making
                    it easy for even those with minimal technical knowledge to navigate effortlessly. Whether you're
                    a novice or an experienced user, you'll appreciate the straightforward layout and how quickly
                    you can accomplish tasks. The developers have done an excellent job simplifying complex
                    features, which enhances the overall user experience. In my opinion, the ease of use is one of
                    the app's standout qualities.`,
      image: require('../assets/img/person_2.jpg'),
      name: 'Vishwajit Ghosh',
      role: 'Supplier'
    },
    {
      rating: 4,
      title: 'Awesome functionality!',
      description: `The functionality of this app is truly remarkable. It offers a wide range of features that
                    cater to various user needs, and it executes them flawlessly. From its seamless integration with
                    other apps to its robust set of tools for productivity, it's clear that the developers have put
                    their heart and soul into creating this app. I highly recommend it to anyone looking for
                    exceptional customer service. The app is well-designed and user-friendly, and it has exceeded 
                    my expectations.`,
      image: require('../assets/img/person_3.jpg'),
      name: 'Sanjay Patel',
      role: 'Supplier'
    }
  ];
export default function Main() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='app-container'>

            <section className="hero-section" id="hero">
                <div className="wave">
                    <svg width="100%" height="355px" viewBox="0 0 1920 355" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <g id="Page-1" stroke="none"
                            // stroke-width="1" 
                            fill="none"
                        // fill-rule="evenodd"
                        >
                            <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                                <path
                                    d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z"
                                    id="Path"></path>
                            </g>
                        </g>
                    </svg>
                </div>

                <div className="container">
                    <div className="row  align-content-center" >
                        <div className="col-8 text-center text-lg-start">
                            <h1 data-aos="fade-right" style={{ fontSize: "60px", color: "White", fontWeight: "700", letterSpacing: "0.5px" }}>
                                Homemet Your True mate
                            </h1>
                            <p
                                // style="font-size: 20px; color: white; letter-spacing: 0.5px" 
                                style={{ fontSize: "20px", color: "white", letterSpacing: "0.5px" }}
                                data-aos="fade-right" data-aos-delay="100">
                                Building the Revolutionary, Transparent Platform for the Construction World.
                            </p>
                        </div>
                        <div className="col-4  iphone-wrap ">
                            <img src={require("../assets/img/HomePage/PartnerApp_Login.jpg")} alt="Image" className="phone-1" data-aos="fade-right" />
                            <img src={require("../assets/img/HomePage/PartnerApp_Dashboard.jpg")} alt="Image" className="phone-2" data-aos="fade-right"
                                data-aos-delay="200" />
                        </div>
                    </div>
                </div>
            </section>
            <main id="main">
                {/* <!-- ======= Home Section ======= --> */}
                <section className="section">
                    <div className="container">
                        <div className="row justify-content-center text-center mb-5">
                            <div className="col-md-8" data-aos="fade-up">
                                <h2 className="section-heading">What is HOMEMET?</h2>
                                <p>
                                    Homemet is a versatile platform that serves both individuals looking to build their dream homes and farmers in need of agricultural services. We provide a variety of building materials and services, and agricultural support from best-rated vendors through our robust B2C network. We are one of the emerging leaders in India to make construction more organized and easier and complete the ecosystem through technology.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4" data-aos="fade-up">
                                <div className="feature-1 text-center">
                                    <div className="wrap-icon icon-1">
                                        <i className="bi bi-people"></i>
                                    </div>
                                    <h3 className="mb-3">No Middleman and No Hidden Costs</h3>
                                    <p>Our Commitment to Transparent and Direct Service.</p>
                                </div>
                            </div>
                            <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="feature-1 text-center">
                                    <div className="wrap-icon icon-1">
                                        <i className="bi bi-brightness-high"></i>
                                    </div>
                                    <h3 className="mb-3">Quotation and Estimation</h3>
                                    <p>We leverage our network of top-rated vendors to ensure you receive the best quotation and service estimation, saving you time and money.</p>
                                </div>
                            </div>
                            <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                                <div className="feature-1 text-center">
                                    <div className="wrap-icon icon-1">
                                        <i className="bi bi-bar-chart"></i>
                                    </div>
                                    <h3 className="mb-3">Open Market Exposure</h3>
                                    <p>Unlocking Open Market Exposure: Expanding Your Business Horizons without marketing expenditure.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row justify-content-center text-center mb-5">
                            <div className="col-md-8" data-aos="fade-up">
                                <h2 className="section-heading">How HOMEMET Works?</h2>
                            </div>
                        </div>

                        <div className="row justify-content-center text-center mb-5" data-aos="fade">
                            <div className="col-md-10 mb-5">
                                <img src={require("../assets/img/HomePage/VendorLanding_WebOrderFlow.jpg")} alt="Image" className="img-fluid" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="step">
                                    <span className="number">01</span>
                                    <h3>Sign Up</h3>
                                    <p>Register yourself as Customer or Vendor in one click.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="step">
                                    <span className="number">02</span>
                                    <h3>Create Profile</h3>
                                    <p>Create a profile just by your phone number and name.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="step">
                                    <span className="number">03</span>
                                    <h3>Enjoy the App</h3>
                                    <p>Discover and Enjoy the Ultimate App for Building Materials, Services, and Agricultural Support!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-4 me-auto">
                                <h2 className="mb-4">Be Our Valued Customer Today!</h2>
                                <p className="mb-4">
                                    Are you planning a construction project, renovating your dream home, or managing your farm? Look no
                                    further! We've got everything you need under one roof. Welcome to Homemet - Your Trusted Partner for
                                    Building Materials, Services, and Agricultural needs.
                                </p>
                                <p>
                                    <a href="customer" className="btn btn-primary">Get Started...</a>
                                </p>
                            </div>
                            <div className="col-md-6" data-aos="fade-left">
                                <img src={require("../assets/img/HomePage/Productd.jpg")} alt="Image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-4 ms-auto order-2">
                                <h2 className="mb-4">Be a Super Supplier!</h2>
                                <p className="mb-4">
                                    Expand Your Reach: Partnering with Homemet is your gateway to new business opportunities, increased sales,
                                    and enhanced brand visibility. We value our material provider relationships and are dedicated to fostering
                                    long-term, mutually beneficial partnerships.
                                </p>
                                <p>
                                    <a href="vendor" className="btn btn-primary">Get Started...</a>
                                </p>
                            </div>
                            <div className="col-md-6" data-aos="fade-right">
                                <img src={require("../assets/img/HomePage/Vendor_PF.jpg")} alt="Image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>

                <Carousel title="Our Happy Users" reviews={reviewsData} />


                {/* <!-- ======= CTA Section ======= --> */}
                <section className="section cta-section">
                    <div className="container  align-items-center justify-content-center ">
                        <div className="mb-5">
                            <h3>Download Homemet now, experience the future of building and farming</h3>
                        </div>
                        <div className=" d-flex  align-items-center justify-content-between text-center ">
                            <p>
                                <a
                                    // style="width: 300px;" 
                                    target="_blank" href="https://www.indusappstore.com/details/indus/1055115"
                                    className=" d-inline-flex align-items-center">
                                    <img src={require("../assets/img/AppStore/PartnerApp-Indus.png")} alt="" className="img-fluid" />
                                </a>
                            </p>
                            <p>
                                <a
                                    // style="width: 300px;" 
                                    target="_blank" href="https://play.google.com/store/apps/details?id=com.homemetpartner"
                                    className=" d-inline-flex align-items-center">
                                    <img src={require("../assets/img/AppStore/PartnerApp-PlayStore.png")} alt="" className="img-fluid" />
                                </a>
                            </p>
                            <p>
                                <a
                                    // style="width: 300px;"
                                    target="_blank" href="https://play.google.com/store/apps/details?id=com.homemetcustomer"
                                    className=" d-inline-flex align-items-center">
                                    <img src={require("../assets/img/AppStore/CustometrApp-PlayStore.png")} alt="" className="img-fluid" />
                                </a>
                            </p>
                        </div>
                    </div>
                </section>
                {/* <!-- End CTA Section --> */}
            </main>
        </div>
    )
}
