import React from 'react'
import { BrowserRouter,  Route, Routes } from 'react-router-dom'
import Dashboard from './Dashboard'
import CustomerPrivacyPolicy from './pages/PrivacyPolicies/CustomerPrivacyPolicy'
import VendorPrivacyPolicy from './pages/PrivacyPolicies/VendorPrivacyPolicy'
import HeroPrivacyPolicy from './pages/PrivacyPolicies/HeroPrivacyPolicy'
import RefundPrivacyPolicy from './pages/PrivacyPolicies/Refund-Policy'
import CustomerTermsAndConditions from './pages/Terms&Conditions/CustomerTerms&Conditions'
import VendorTermsAndConditions from './pages/Terms&Conditions/VendorTerms&Conditions'
import HeroTermsAndConditions from './pages/Terms&Conditions/HeroTerms&Conditions'
import ScrollToTop from './components/ScrollToTop'

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Dashboard screen="main" />} />
        <Route path="/customer" element={<Dashboard screen="customer" />} />
        <Route path="/vendor" element={<Dashboard screen="vendor" />} />
        <Route path="/contact" element={<Dashboard screen="contact" />} />
        <Route path="/services/:service/:location" element={<Dashboard screen="services" />} />

        <Route path="/refund-policy" element={<RefundPrivacyPolicy />} />


        <Route path="/privacy-policy-customer" element={<CustomerPrivacyPolicy />} />
        <Route path="/privacy-policy-vendor" element={<VendorPrivacyPolicy />} />
        <Route path="/privacy-policy-hero" element={<HeroPrivacyPolicy />} />

        <Route path="/terms-customer" element={<CustomerTermsAndConditions />} />
        <Route path="/terms-vendor" element={<VendorTermsAndConditions />} />
        <Route path="/terms-hero" element={<HeroTermsAndConditions />} />






      </Routes>
    </BrowserRouter >
  )
}
