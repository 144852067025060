import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="footer" role="contentinfo">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mb-4 mb-md-0">
                        <h3>About Homemet</h3>
                        <p>
                            Homemet is your ultimate destination for all your home construction and agricultural needs. Whether you're
                            building your dream home or tending to your farm, we've got you covered. Our comprehensive platform offers a
                            wide range of building materials and services, as well as expert agricultural solutions.
                        </p>
                        <p className="social">
                            <a href="https://twitter.com/officialhomemet" target="_blank" rel="noopener noreferrer">
                                <span className="bi bi-twitter"></span>
                            </a>
                            <a href="https://www.facebook.com/OfficialHomemet" target="_blank" rel="noopener noreferrer">
                                <span className="bi bi-facebook"></span>
                            </a>
                            <a href="https://www.instagram.com/officialhomemet" target="_blank" rel="noopener noreferrer">
                                <span className="bi bi-instagram"></span>
                            </a>
                            <a href="https://www.linkedin.com/company/officialhomemet" target="_blank" rel="noopener noreferrer">
                                <span className="bi bi-linkedin"></span>
                            </a>
                            <a href="https://www.youtube.com/channel/UCB0C-26yxIF7O6oDfW3YVsQ" target="_blank" rel="noopener noreferrer">
                                <span className="bi bi-youtube"></span>
                            </a>
                        </p>
                    </div>
                    <div className="col-md-8 ms-auto">
                        <div className="row site-section pt-0">
                            <div className="col-md-4 mb-4 mb-md-0">
                                <h3>Navigation</h3>
                                <ul className="list-unstyled">
                                    <li><Link to="/contact">Contact Us</Link></li>
                                    <li><Link to="/customer" >Customer</Link></li>
                                    <li><Link to="/vendor" >Vendor</Link></li>
                                    <li><Link to="/refund-policy">Cancellation or Refund Policy</Link></li>

                                  
                                </ul>
                            </div>
                            <div className="col-md-4 mb-4 mb-md-0">
                                <h3>Our Policies</h3>
                                <ul className="list-unstyled">

                                    <li><Link to="/privacy-policy-vendor">Privacy Policy Vendor</Link></li>
                                    <li><Link to="/terms-vendor">Vendor Terms & Condition</Link></li>

                                    <li><Link to="/privacy-policy-customer">Privacy Policy Customer</Link></li>
                                    <li><Link to="/terms-customer">Customer Terms & Condition</Link></li>

                                    <li><Link to="/privacy-policy-hero">Privacy Policy Hero</Link></li>
                                    <li><Link to="/terms-hero">Hero Terms & Condition</Link></li>
                                </ul>
                            </div>

                            <div className="col-md-4 mb-4 mb-md-0">
                                <h3>Active Service Locations</h3>
                                <ul className="list-unstyled">
                                    <li><Link to="/">Chhattisgarh</Link></li>
                                    <li><Link to="/">Madhya Pradesh</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center text-center">
                    <div className="col-md-7">
                        <p className="copyright">
                            &copy; {new Date().getFullYear()} Homemet. All Rights Reserved.
                        </p>
                        <div className="credits">
                            Designed by <a href="https://axlesys.in/" target="_blank" rel="noopener noreferrer">Axlesys</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
