import React, { useEffect } from 'react'
import BackToTopButton from '../../components/BackToTopButton'

export default function HeroTermsAndConditions() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <header id="header" style={{ backgroundColor: '#9fdfa0cc' }} className="fixed-top py-3">
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="logo">
                        <a href="#">
                            <img src={require("../../assets/img/HLoader.gif")} alt="" className="img-fluid" />
                        </a>
                    </div>
                </div>
            </header>
            <section className="mt-5 pt-5">
                <div className="container">
                    <article>
                        <header className='text-center'>
                            <h1 id="contents">Hero Terms And Conditions</h1>
                            <h3 id="personal-info" className="heading-s">Last updated: Jun 01, 2024</h3>
                            <h6 id="misc_children">Please read these terms and conditions carefully before using Our Service.</h6>
                        </header>

                        <br />
                        <h2 id="personal-info" className='text-center'>Interpretation and
                            Definitions</h2>
                        <h2 id="misc_children" class="heading-s">Interpretation</h2>
                        <p className='text-color-dark'>The words of which the initial letter is capitalized have meanings defined under the
                            following conditions. The following definitions shall have the same meaning regardless of whether they appear in
                            singular or in plural form.
                        </p>
                        <br />
                        <h2 id="misc_children" class="heading-s">Definitions</h2>
                        <p className='text-color-dark' id="misc_children">For the purposes of these Terms and Conditions:
                            <br />
                            <b>Application</b>means the SaaS-based software program provided by the Company downloaded by You on any
                            electronic device, named Homemet HERO
                            <br />
                            <b>Application Store</b> Store means the digital distribution service operated and developed by Apple Inc.
                            (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.
                            <br />
                            <b>Affiliate</b>means an entity that controls, is controlled by or is under common control with a party, where
                            "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for
                            the election of directors or other managing authority.
                            <br />
                            <b>Country</b> refers to: India
                            <br />
                            <b>Company</b>(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to AXLESYS
                            PRIVATE LIMITED, Vrindavan Plaza, Raigarh.
                            <br />
                            <b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                            <br />
                            <b>Service</b> refers to the Application.
                            <br />
                            <b>Hero</b>refers to the Salesperson (Soldier or Executive).
                            <br />
                            <b>Terms and Conditions</b> (also referred as "Terms") mean these Terms and Conditions that form the entire
                            agreement between You and the Company regarding the use of the Service.
                            <br />
                            <b>Third-party Social Media Service</b> means any services or content (including data, information, products or
                            services) provided by a third party that may be displayed, included or made available by the Service.
                            <br />
                            <b>You</b>mean the individual accessing or using the Service, or the company, or other legal entity on behalf of
                            which such individual is accessing or using the Service, as applicable.
                            <br />

                            We may terminate or suspend Your access immediately, without prior notice or liability, for any reason
                            whatsoever, including without limitation if You br/each these Terms and Conditions.
                            Upon termination, Your right to use the Service will cease immediately.

                        </p>

                        <h2 id="collection" class="heading-s">Acceptance of Terms</h2>

                        <p className='text-color-dark' id="collection_tracking">These are the Terms and Conditions governing the use of this Service and the
                            agreement that operates between You and the Homemet HERO. These Terms and Conditions set out the rights and
                            obligations of all users regarding the use of the Service.
                            <br /><br />
                            Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and
                            Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
                            <br /><br />
                            By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any
                            part of these Terms and Conditions then You may not access the Service.
                            <br /><br />
                            You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
                            <br /><br />
                            Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy
                            Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and
                            disclosure of Your personal information when You use the Application or the Website and tells You about Your
                            privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.

                        </p>


                        <h2 id="use" class="heading-s">Registration & Responsibilities</h2>
                        <p className='text-color-dark'>
                            (a) In order to use certain features of the App, including registering as a HERO and making sales, you may be
                            required to register and create an account. You agree to provide accurate, current, and complete information
                            during the registration process and to update such information as necessary to keep it accurate, current, and
                            complete.
                            <br />
                            (b) You are solely responsible for maintaining the confidentiality of your account credentials and for all
                            activities that occur under your account. You agree to notify us immediately of any unauthorized use of your
                            account or any other br/each of security.
                            <br />
                            (c) The App allows users to register as HERO to make sales of SaaS-based HOMEMET PARTNER App. By registering as
                            a HERO, you represent and warrant that you have the legal authority to act on behalf of the entity you are
                            registering.
                            <br />
                            (d) As a registered HERO, you agree to abide by all applicable laws and regulations governing sales activities,
                            including but not limited to those related to sales tax, licensing, and marketing practices.
                            <br />
                            (e) You agree to accurately represent the products/services you are selling and to provide clear and truthful
                            information to potential buyers.



                        </p>



                        <h2 id="disclosure" class="heading-s">Sales and Transactions</h2>

                        <p className='text-color-dark' id="disclosure_partners">(a) Through the App, registered HERO may make sales of SaaS-based products to other
                            users. You agree to abide by all applicable terms and conditions governing such sales.
                            <br />
                            (b) You acknowledge that we may receive a commission or other compensation for facilitating sales transactions
                            through the App.

                        </p>


                        <h2 id="storage" class="heading-s">HERO Policies</h2>
                        <p className='text-color-dark'>
                            (a) Premium Point-value Policy: Executives are entitled to premium point-value benefits.
                            <br />
                            (b) Monthly Target Policy: Executives are required to meet monthly sales targets.
                            <br />
                            (c) Performance Policy: If an executive fails to meet the Monthly Target Policy, their "premium point-value" and
                            "Two-level earning policy" for those months will not be calculated.
                            <br />
                            (d) Company Activity Participation Policy: Executives must actively participate in company activities.
                            <br />
                            (e) Two-level earning policy: Leaders can earn points from the sales made by their current downline Heroes.
                            <br />
                            (f) Soldier policy: Soldiers are exempt from the following policies "Premium Point-value Policy", "Monthly
                            Target Policy", "Company Activity Participation Policy", and "Performance Policy", "Two-level earning policy".
                            <br />
                            (g) HERO Rights Not Transferable: The rights granted to HERO under these Terms & Conditions are personal to the
                            HERO and may not be transferred, assigned, or sublicensed to any other party.

                        </p>


                        <h2 id="misc" class="heading-s">Pricing, Fee, and Payment Terms</h2>

                        <p className='text-color-dark' id="misc_choice">(a) Homemet HERO App reserves the right to charge you for the different Services you may
                            avail and/or for any other facilities you may opt for, from time to time, on or via the Platform.

                        </p>



                        <h2 id="misc">Our Intellectual Property </h2>

                        <p className='text-color-dark' id="misc_choice">(a) All content, materials, and features available through the App, including but not limited
                            to text, graphics, logos, images, audio clips, and software, are the property of the Homemet HERO. or its
                            licensors and are protected by copyright, trademark, and other intellectual property laws.
                            <br />
                            (b) You are granted a limited, non-exclusive, non-transferable license to access and use the App and its content
                            for your personal and non-commercial use only.
                            <br />
                            (c) You agree not to modify, reproduce, distribute, or create derivative works based on the content of the App
                            without our prior written consent.


                        </p>


                        <h2 id="misc" class="heading-s">Limitation of Liability </h2>

                        <p className='text-color-dark' id="misc_choice">(a) To the fullest extent permitted by law, we disclaim all warranties, express or implied,
                            including but not limited to warranties of merchantability, fitness for a particular purpose, and
                            non-infringement. We shall not be liable for any direct, indirect, incidental, special, or consequential damages
                            arising out of or relating to your use of the App.
                            <br />
                            (b) In no event shall our total liability to you for all claims arising out of or relating to these Terms or
                            your use of the App exceed the amount paid by you, if any, for access to the App.
                        </p>



                        <h2 id="misc" class="heading-s">Terms and Termination</h2>

                        <p className='text-color-dark' id="misc_choice">(a)	These Terms shall remain in effect unless terminated in accordance with the terms hereunder.
                            <br />
                            (b)	We may restrict, deactivate, or terminate your access to, or use of, the Services, or any portion thereof, (i) immediately and at any point at our sole discretion, (A) if you violate or br/each any of the obligations, responsibilities, or covenants under these Terms,
                            <br /> (B) when you cease to become a user of our Services, (C) you do not, or are likely not to qualify under applicable law, or the standards and policies of HOEMEMET HERO or its affiliates, to access and use the Services, or (D) violate or br/each the Community Guidelines, (ii) upon 30 (Thirty) days’ prior written notice to you, or (iii) immediately for any legitimate business, legal, or regulatory reason.
                            <br />
                            (c)	Upon termination of these Terms:
                            i.	the Account will expire;
                            ii.	the Services will “time out”; and
                            iii.	these Terms shall terminate, except for those clauses that are expressly, or by implication, intended to survive termination or expiry.

                        </p>



                        <h2 id="misc" class="heading-s">Disclaimers and Warranties</h2>

                        <p className='text-color-dark' id="misc_choice">(a)	The Services are provided on an “as is” basis without warranty of any kind, express, implied, statutory or otherwise, including without limitation the implied warranties of title, non-infringement, merchantability, or fitness for a particular purpose. Without limiting the foregoing, we make no warranty that the Services will meet your requirements or expectations.
                            <br />
                            (b)	No advice or information, whether oral or written, obtained by you from us shall create any warranty that is not expressly stated in the Terms.
                            <br />
                            (c)	You agree and acknowledge that soliciting or receiving services from any Service Professional independently is solely at your own risk, and in such an event, you waive any rights that you may have under these Terms.
                            <br />
                            (d)	We do not guarantee or warrant and we make no representation whatsoever regarding the reliability, quality, or suitability of the Service.
                            <br />
                            (e)	You hereby accept full responsibility for any consequences that may arise from your use of the Services and expressly agree and acknowledge that we shall have absolutely no liability in this regard.
                            <br />
                            (f)	To the fullest extent permissible by law, we, our affiliates, and our related parties, each disclaim all liability for any loss or damage arising out of, or due to:
                            a.	your use of, inability to use, or availability or unavailability of the Services;
                            b.	the failure of the Services to remain operational for any period of time;
                            c.	the loss of any User Content and any other data in connection with your use of the Services.
                            <br />
                            (g)	To the maximum extent permitted by law, our liability shall be limited to the amount of commission we receive in respect of a particular booking made on the Platform. In no event shall our total liability to you in connection with these Terms exceed INR 10,000 (Rupees Ten Thousand).
                            <br />
                            (h)	Nothing in these Terms will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit.
                            <br />
                            (i)	Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.


                        </p>


                        <h2 id="misc" class="heading-s">Indemnity</h2>

                        <p className='text-color-dark' id="misc_choice">You shall indemnify, defend at our option, and hold us, our parent companies, subsidiaries, affiliates, and our officers, employees, directors, agents, and representatives, harmless from and against any claim, demand, lawsuits, judicial proceeding, losses, liabilities, damages, and costs (including, without limitation, all damages, liabilities, settlements, and attorneys’ fees), due to or arising out of your access to the Services, use of the Services or material Services, violation of these Terms, or any violation of these Terms by any third party who may use your Account.

                        </p>


                        <h2 id="misc" class="heading-s">Jurisdiction, Governing Laws, and Dispute Resolution </h2>

                        <p className='text-color-dark' id="misc_choice">Homemet HERO These Terms shall be governed by and construed and enforced in accordance with the laws of India. Subject to other provisions in this clause, the district court in Raigarh, Chhattisgarh shall have exclusive jurisdiction over all issues arising out of these terms or the use of the Services.


                        </p>



                        <h2 id="misc" class="heading-s">Miscellaneous Provisions</h2>

                        <p className='text-color-dark'id="misc_choice">(a)	Changes to Terms: The Terms are subject to revisions at any time, as determined by us, and all changes are effective immediately upon being posted on the Platform. It is your responsibility to review these Terms periodically for any updates or changes. You will be deemed to have accepted the changes made to these Terms if you continue to use the Platform once it has been posted.
                            <br />
                            (b)	Modification to the Services: We reserve the right at any time to add, modify, or discontinue, temporarily or permanently, the Services (or any part thereof), with or without cause. We shall not be liable for any such addition, modification, suspension, or discontinuation of the Services.
                            <br />
                            (c)	Severability: If any provision of these Terms is determined by any court or other competent authority to be unlawful or unenforceable, the other provisions of these Terms will continue to be in effect. If any unlawful or unenforceable provision would be lawful or enforceable if a part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear intention of the clause, in which case the entirety of the relevant provision will be deemed to be deleted).
                            <br />
                            (d)	Notice: All notices, requests, demands, and determinations for us under these Terms (other than routine operational communications) shall be sent to admin@axlesys.in
                            <br />
                            (e)	Third Party Rights: No third party shall have any rights to enforce any terms contained herein.
                            <br />
                            (f)	Force Majeure: We shall have no liability to you if we are prevented from or delayed in performing our obligations, or from carrying on our business, by acts, events, omissions, or accidents beyond our reasonable control, including without limitation, strikes, failure of a utility service or telecommunications network, act of God, war, riot, civil commotion, malicious damage, or compliance with any law or governmental order, rule, regulation, or direction.

                        </p>


                        <h2 id="misc" class="heading-s">Contact Us</h2>

                        <p className='text-color-dark' id="misc_choice">If you have any questions about these Terms and Conditions, You can contact us:
                            <br />
                            By email: admin@axlesys.in
                            <br />
                            By visiting this page on our website: https://homemet.in/contact
                            <br />
                            By mail: 117, 118 vrindavan plaza, Raigarh, Chhattisgarh 496001


                        </p>



                        <h5 id="misc" className='heading-s text-color-dark text-center'>This Terms and Conditions page was created and modified/generated by Homemet</h5>
                    </article>
                </div>
            </section>
            <BackToTopButton/>
        </div >
    )
}

