import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const styles = {
    body: {
        fontFamily: "'Poppins', sans-serif",
        backgroundColor: '#f4f4f9',
        margin: 0,
        padding: 0,
        color: '#333',
    },
    container: {
        width: '90%',
        margin: '50px auto',
    },
    serviceGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        justifyContent: 'center',
    },
    serviceCard: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        width: 'calc(33.333% - 20px)',
        maxWidth: '300px',
        transition: 'transform 0.3s ease',
    },
    serviceCardHover: {
        transform: 'translateY(-30px)',
    },
    serviceIcon: {
        backgroundColor: '#7ed957',
        padding: '20px',
        textAlign: 'center',
        fontSize: '30px',
        color: '#fff',
    },
    serviceContent: {
        padding: '20px',
    },
    serviceTitle: {
        fontSize: '1.2rem',
        fontWeight: 600,
        margin: '10px 0',
        color: '#333',
    },
    serviceTitleSpan: {
        display: 'block',
        fontSize: '0.9rem',
        color: '#666',
    },
    serviceDescription: {
        fontSize: '0.95rem',
        marginBottom: '10px',
        color: '#777',
    },
    serviceLocation: {
        fontSize: '0.9rem',
        color: '#999',
    },
    '@media(maxWidth: 768px)': {
        serviceCard: {
            width: 'calc(50% - 20px)',
        },
    },
    '@media(maxWidth: 576px)': {
        serviceCard: {
            width: '100%',
        },
    },
};
export default function Services() {


    const { service, location } = useParams();

    // Capitalize service and location for title and display
    const capitalizedService = service.charAt(0).toUpperCase() + service.slice(1);
    const capitalizedLocation = location.charAt(0).toUpperCase() + location.slice(1);

    function setPageTitle(service, location) {
        document.title = `${service.charAt(0).toUpperCase() + service.slice(1)} in ${location.charAt(0).toUpperCase() + location.slice(1)} - Your Trusted Provider`;
    }

    setPageTitle(service, location);
    // const title = `${service.charAt(0).toUpperCase() + service.slice(1)} Services in ${location.charAt(0).toUpperCase() + location.slice(1)}`;
    const description = `Looking for ${service} services in ${location}? Discover top providers and expert solutions here!`;
    const title = `${capitalizedService} Services in ${capitalizedLocation} - Your Trusted Provider`;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    // Canonical URL
    const canonicalUrl = `https://homemet.in/services/${service}/${location}`;

    // Structured Data (JSON-LD) for search engines
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": capitalizedService,
        "areaServed": {
            "@type": "Place",
            "name": capitalizedLocation
        },
        "provider": {
            "@type": "Organization",
            "name": "Homemet",
            "url": "https://homemet.in"
        }
    };

    return (
        <div>
            <Helmet>
                <title>{`${service} in ${location}`}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonicalUrl} />
                <meta name="keywords" content={`${service}, ${location}, services`} />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <section>
                <section className="hero-section-custom hero-section inner-page">
                    <div className="wave">
                        <svg
                            width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        // xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                                    <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path"></path>
                                </g>
                            </g>
                        </svg>

                    </div>

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div className="headr-align-customer">
                                    <span className="headr-text-1">{title}</span>
                                    <div>
                                        <span className="headr-text-2">
                                            Get Your
                                        </span>

                                        <span className="headr-text-3 ml-2">
                                            Services at Home
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <h3 style={{ textAlign: 'center' }}>We offer high-quality service services in Location. Contact us for the best prices and reliable service.</h3>
                <p style={{ textAlign: 'center', marginBottom: '40px' }}>Best services at best price in your location.</p>
                <div style={styles.container}>
                    {/* <h2 style="text-align:center; margin-bottom: 40px;">Our Services</h2> */}
                    <div style={styles.serviceGrid}>
                        {/* Service 1 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-cubes" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Red Bricks <span>लाल ईंट</span></h3>
                                <p style={styles.serviceDescription}>Suppliers at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 2 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-box" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Fly Ash Bricks <span>फ्लाई ऐश की ईंट</span></h3>
                                <p style={styles.serviceDescription}>Suppliers at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 3 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-th-large" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>AAC Blocks <span>एएसी ब्लॉक</span></h3>
                                <p style={styles.serviceDescription}>Suppliers at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 4 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-water" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Sand <span>रेत</span></h3>
                                <p style={styles.serviceDescription}>Suppliers at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 5 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-mountain" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Gitti <span>गिट्टी</span></h3>
                                <p style={styles.serviceDescription}>Suppliers at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 6 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-seedling" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Soil <span>मिट्टी</span></h3>
                                <p style={styles.serviceDescription}>Suppliers at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 7 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-industry" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Cement <span>सीमेंट</span></h3>
                                <p style={styles.serviceDescription}>Suppliers at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 8 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-bars" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>TMT Sariya <span>टीएमटी छड़</span></h3>
                                <p style={styles.serviceDescription}>Suppliers at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 9 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-hammer" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Civil Contractor <span>सिविल ठेकेदार</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 10 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-wrench" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Fabrication <span>वेल्डिंग और निर्माण</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 11 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-th" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Tiles Work <span>टाइल्स का काम</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 12 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-chevron-circle-up" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>False Ceiling <span>फॉल सीलिंग कार्य</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 13 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-paint-roller" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Painting <span>पुताई कार्य</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 14 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-ruler-combined" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>ACP-Glass <span>एसीपी-शीशा कार्य</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 15 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-window-maximize" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>ACP &amp; Glaze <span>एसीपी और ग्लेज़</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 16 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-glass-martini-alt" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Toughened Glass <span>टफेंड ग्लॉस</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 17 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-box" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Aluminium Section Work <span>एल्युमिनियम सेक्शन</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 18 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-hard-hat" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Labour Supplier <span>मजदूर ठेकेदार</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 19 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-tools" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Carpenter <span>बढ़ई</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 20 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-toolbox" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Carpenter: With Material <span>बढ़ई: विथ-मटेरियल</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 21 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-bolt" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Wiring Contractor <span>वायरिंग ठेकेदार</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 22 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-faucet" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Home Plumbing <span>प्लंबर नल कार्य</span></h3>
                                <p style={styles.serviceDescription}>Contractors at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 23 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-level-up-alt" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Land Work Levelling <span>भूमि कार्य लेवलिंग</span></h3>
                                <p style={styles.serviceDescription}>Work at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 24 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-tractor" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Tractor <span>ट्रैक्टर</span></h3>
                                <p style={styles.serviceDescription}>Work at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 25 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-truck-monster" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>JCB <span>जेसीबी</span></h3>
                                <p style={styles.serviceDescription}>Work at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 26 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-drill" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Borewell Machine <span>बोरवेल मशीन</span></h3>
                                <p style={styles.serviceDescription}>Work at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 27 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-truck-pickup" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Crane <span>क्रेन</span></h3>
                                <p style={styles.serviceDescription}>Work at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 28 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-truck" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Bulldozer <span>बुलडोज़र</span></h3>
                                <p style={styles.serviceDescription}>Work at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 29 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-compactor" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Road Roller <span>रोड रोलर</span></h3>
                                <p style={styles.serviceDescription}>Work at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 30 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-dumpster" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>HYVA <span>हाइवा</span></h3>
                                <p style={styles.serviceDescription}>Work at best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 31 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-door-closed" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Door Frame <span>दरवाजा चौखट</span></h3>
                                <p style={styles.serviceDescription}>At best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 32 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-utensils" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Modular Kitchen <span>मोडुलर किचन</span></h3>
                                <p style={styles.serviceDescription}>At best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 33 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-parking" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Parking Tiles <span>पार्किंग टाइल्स</span></h3>
                                <p style={styles.serviceDescription}>At best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 34 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-house-user" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Home Tiles <span>होम टाइल्स</span></h3>
                                <p style={styles.serviceDescription}>At best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 35 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-car" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Marble Stone <span>संगमरमर पत्थर</span></h3>
                                <p style={styles.serviceDescription}>At best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 36 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-chess-board" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Granite Stone <span>ग्रेनाइट पत्थर</span></h3>
                                <p style={styles.serviceDescription}>At best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 37 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-globe" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Land Plots <span>भूमि प्लॉट</span></h3>
                                <p style={styles.serviceDescription}>At best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 38 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-school" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>All Kinds of Tiles <span>सभी प्रकार की टाइल्स</span></h3>
                                <p style={styles.serviceDescription}>At best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 39 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-tools" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>All Kinds of Works <span>सभी प्रकार के कार्य</span></h3>
                                <p style={styles.serviceDescription}>At best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                        {/* Service 40 */}
                        <div style={styles.serviceCard}>
                            <div style={styles.serviceIcon}>
                                <i className="fas fa-building" />
                            </div>
                            <div style={styles.serviceContent}>
                                <h3 style={styles.serviceTitle}>Building Construction <span>निर्माण</span></h3>
                                <p style={styles.serviceDescription}>At best price</p>
                                <p style={styles.serviceLocation}>Raipur</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
