import React, { useState, useEffect } from 'react';
import BackToTopButton from "../../components/BackToTopButton.jsx";

export default function CustomerPrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container">
            <header id="header" style={{ backgroundColor: '#9fdfa0cc' }} className="fixed-top py-3">
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="logo">
                        <a href="#">
                            <img src={require("../../assets/img/HLoader.gif")} alt="" className="img-fluid" />
                        </a>
                    </div>
                </div>
            </header>

            <section className="mt-5 pt-5">
                <div className="container">
                    <h1 className="text-center mb-4" id="contents">Homemet Privacy Policy</h1>
                    <article>
                        <section className="mb-5">
                            <h2 id="personal-info">Privacy Policy</h2>
                            <p>
                                Axlesys Private Limited built the HOMEMET app as an ad-supported app. This SERVICE is provided by Axlesys Private Limited at no cost and is intended for use as is.
                                This page is used to inform visitors regarding our policies regarding the collection, use, and disclosure of Personal Information if anyone decides to use our Service.
                                If you choose to use our Service, then you agree to the collection and use of information about this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="collection">Information Collection and Use</h2>
                            <p>
                                To provide you with an enhanced experience while using our service, we may request certain personally identifiable information, including but not limited to:
                                <ul>
                                    <li>Name</li>
                                    <li>Email address</li>
                                    <li>Mobile number</li>
                                    <li>Device-Id</li>
                                    <li>Location</li>
                                    <li>Address</li>
                                </ul>
                                Additionally, we access your location to get a nearby service provider and ensure your request gets delivered quickly, and it is also used for ads and analytics.

                                We collect this information to optimize the functionality of our service and offer you a personalized and efficient experience.

                                Your information may be utilized for the following purposes:
                                <ul>
                                    <li>Providing customer support and facilitating communication between users.</li>
                                    <li>Utilizing location information for targeted advertising and analytics to enhance our service.</li>
                                    <li>Personalize your experience on the app to better suit your preferences.</li>
                                    <li>Ensuring security by verifying your identity and preventing fraudulent activities.</li>
                                    <li>Furthermore, we may retain and utilize your information to comply with legal and regulatory requirements.</li>
                                </ul>
                                The information collected by us will be stored and utilized in accordance with this Privacy Policy. Please note that our app may incorporate third-party services, which may also gather information to identify you. We encourage you to review the privacy policies of these third-party services for more details on their data collection and usage practices.
                                Link to the privacy policy of third-party service providers used by the app:
                                <ul>
                                    <li>Google Play Services</li>
                                    <li>Google Cloud Console</li>
                                    <li>Firebase services</li>
                                </ul>
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="use">Log Data</h2>
                            <p>
                                We want to inform you that whenever you use our Service, in the case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="disclosure">Cookies</h2>
                            <p>
                                Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
                                This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="storage">Service Providers</h2>
                            <p>
                                We may employ third-party companies and individuals due to the following reasons:
                                <ul>
                                    <li>To facilitate our Service;</li>
                                    <li>To provide the Service on our behalf;</li>
                                    <li>To perform Service-related services;</li>
                                    <li>To assist us in analyzing how our Service is used.</li>
                                </ul>
                                We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="misc">Security</h2>
                            <p>
                                We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="misc">Links to Other Sites</h2>
                            <p>
                                This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="misc">Children’s Privacy</h2>
                            <p>
                                These Services do not address anyone under the age of 18. We do not knowingly collect personally identifiable information from children under 18 years of age. In the case we discover that a child under 18 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take the necessary actions.
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="misc">Changes to This Privacy Policy</h2>
                            <p>
                                We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
                                This policy is effective as of June 01, 2024
                            </p>
                        </section>

                        <section className="mb-5">
                            <h2 id="misc">Contact Us</h2>
                            <p>
                                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at admin@axlesys.in.
                            </p>
                        </section>

                        <section className="mb-5 text-center">
                            <h2 id="misc">This privacy policy page was created and modified/generated by
                                <br />
                                Axlesys Pvt. Ltd.</h2>
                        </section>
                    </article>
                </div>
            </section>
            <BackToTopButton />
        </div>
    );
}
