import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Footer from './components/Footer';
import DynamicScreen from './components/DynamicScreen';
import Header from './components/Header';

const Dashboard = ({ screen }) => {
    console.log(screen)
   
   

    
    return (
        <div className="app-container">
            <Header/>
            <main>
                <DynamicScreen screenName={screen} />
            </main>
            <Footer />
        </div>
    );
};

export default Dashboard;
