import React, { useEffect } from 'react'

export default function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main id="main">
            <section className="hero-section inner-page">
                <div className="wave">
                    <svg width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                                <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path" />
                            </g>
                        </g>
                    </svg>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="col-md-7 text-center hero-text">
                                    <h1 data-aos="fade-up" data-aos-delay>Get in touch</h1>
                                    <p className="mb-5" data-aos="fade-up" data-aos-delay={100}>Thank you for visiting our website and taking the time to get in touch. We value your feedback, inquiries, and suggestions, and we're excited to connect with you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row mb-5 align-items-end">
                        <div className="col-md-6" data-aos="fade-up">
                            <h2>Contact Form</h2>
                            <p className="mb-0">Please fill out the contact form below with your details and message, and we'll get back to you as soon as possible.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 ms-auto order-2" data-aos="fade-up">
                            <ul className="list-unstyled">
                                <li className="mb-3">
                                    <strong className="d-block mb-1">Address</strong>
                                    <span>117, 118, Vrindavan plaza,Raigarh (CG) , 496001
                                    </span>
                                </li>
                                <li className="mb-3">
                                    <strong className="d-block mb-1">Phone</strong>
                                    <span>07762-296038/8770399585
                                    </span>
                                </li>
                                <li className="mb-3">
                                    <strong className="d-block mb-1">Email</strong>
                                    <span>admin@axlesys.in</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 mb-5 mb-md-0" data-aos="fade-up">
                            {/* <form action="contact.php" method="post" role="form" class="php-email-form"> */}
                            <form id="contactForm" className="php-email-form">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" name="name" className="form-control" id="name" required />
                                    </div>
                                    <div className="col-md-6 form-group mt-3 mt-md-0">
                                        <label htmlFor="name">Email</label>
                                        <input type="email" name="email" className="form-control" id="email" required />
                                    </div>
                                    <div className="col-md-12 form-group mt-3">
                                        <label htmlFor="name">Subject</label>
                                        <input type="text" name="subject" className="form-control" id="subject" required />
                                    </div>
                                    <div className="col-md-12 form-group mt-3">
                                        <label htmlFor="name">Message</label>
                                        <textarea className="form-control" name="message" id="message" required defaultValue={""} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <input type="submit" className="btn btn-primary d-block w-100" defaultValue="Send Message" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section cta-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 me-auto text-center text-md-start mb-5 mb-md-0">
                            <h2>Download Homemet now, experience the future of building and farming</h2>
                        </div>
                        <div className="col-md-4 text-center text-md-end">
                            <p>
                                <a style={{ width: '300px' }} target="_blank" href="https://www.indusappstore.com/details/indus/1055115" className=" d-inline-flex align-items-center">
                                    <img src={require("../assets/img/AppStore/PartnerApp-Indus.png")} alt="" className="img-fluid" />
                                    {/* <i  class="bx bxl-play-store"></i><span>Customer App</span> */}
                                </a>
                            </p>
                            <p>
                                <a style={{ width: '300px' }} target="_blank" href="https://play.google.com/store/apps/details?id=com.homemetpartner" className=" d-inline-flex align-items-center">
                                    <img src={require("../assets/img/AppStore/PartnerApp-PlayStore.png")} alt="" className="img-fluid" />
                                    {/* <i  class="bx bxl-play-store"></i><span>Partner App</span></a> */}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    )
}
